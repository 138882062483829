import BenefitsSection from './components/BenefitsSection'
import FeatureSection from './components/FeatureSection'
import Header from './components/Header'
import HeroSection from './components/HeroSection'
import IngredientsSection from './components/IngredientsSection'
import ResultsSection from './components/ResultsSection'

function App() {
  return (
    <div className="">
      <Header />
      <HeroSection />
      <BenefitsSection />
      <FeatureSection />
      <ResultsSection />
      <IngredientsSection />
    </div>
  )
}

export default App
