import featureImg from '../assets/p 6.png';
const FeatureSection = () => {
  return (
    // featureSection__img
    <section className="relative my-8 max-w-screen-xl md:max-w-screen-lg mx-auto w-full overflow-hidden tall:h-screen">
      <img
        src={featureImg}
        alt="img"
        // sm:h-auto
        className=" object-cover  w-full h-[99vh] sm:h-auto tall:h-full  relative md:left-1 "
      />

      <div className="absolute top-16 sm:top-14 md:top-16 lg:top-32 left-1/2 -translate-x-[50%] w-full px-10 max-w-screen-xl mx-auto tall:top-8 medium:top-2">
        <h6 className="font-bold phone:text-xl taller:text-2xl md:!text-2xl lg:!text-3xl text-center uppercase">
          Effective And Natural <br className="hidden taller:block" />
          Anti Stretch Marks Formula
        </h6>
        <p className="font-medium md:w-[68%] mx-auto phone:mt-0 mt-4 text-sm md:text-base  text-center">
          Anti-Stretching Marks Cream acts as a preventative measure or to stop
          the appearance of stretch marks. It helps to reduce stretch marks that
          you already have, and prevents them from getting worse by improving
          your skin's texture.
          <br className="hidden sm:block" />
          <br className="hidden taller:block" /> Anti Stretch Mark Cream helps
          promote healthy and glowing skin that looks firm, toned and youthful.
        </p>
      </div>
      <div className="absolute left-1/2 translate-x-[-50%] lg:-translate-x-[60%] w-full lg:w-fit px-10 bottom-12 sm:bottom-20 md:bottom-28  lg:bottom-44 medium:bottom-3 tall:bottom-6 taller:bottom-8 phone:bottom-6 lg:tallest:bottom-44 right-0  lg:-ml-6">
        <h6 className="font-bold phone:text-xl taller:text-2xl md:!text-2xl lg:!text-3xl   uppercase text-center mx-auto lg:w-[150%]">
          Say Goodbye to Stretch Marks without{' '}
          <br className="hidden taller:block" /> pain or scarring with laser
          treatment.
        </h6>
        <p className="font-medium mt-4 tall:mt-1 md:w-0 text-sm  md:text-base md:min-w-[78%] mx-auto lg:min-w-[125%] text-center pb-4">
          Stretch marks are common. Between 50% and 90% of all people who are
          pregnant develop stretch marks.
          <br className="hidden taller:block" />
          <br />
          Skincare is a process and there is no quick fix solution, but the
          results using the right skincare regimen and products can be fast and
          effective!
        </p>
      </div>
    </section>
  );
};
export default FeatureSection;
