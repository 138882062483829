import coconutImg from '../assets/p 11.png'
import cocoaImg from '../assets/p 12.png'
import oliveImg from '../assets/p 13.png'
import aloeveraImg from '../assets/p 14.png'
import bgImg from '../assets/k 1.jpg'
const ingredientsData = [
  {
    id: 0,
    name: 'Coconut Oil',
    description:
      'Coconut oil is known for its hydrating and nourishing properties, as well as its ability to soothe irritated skin.',
    img: coconutImg,
  },
  {
    id: 1,
    name: 'Organic Cocoa Butter',
    description:
      'Cocoa butter is rich in antioxidants and is an excellent source of vitamins that help with collagen production and elasticity. It forms a protective barrier over your skin, locking in moisture and preventing dehydration.',
    img: cocoaImg,
  },
  {
    id: 3,
    name: 'Olive Oil',
    description:
      'The natural alpha-hydroxy acids in our olive oil not only moisturize, smooth, condition, and soothe your skin, but also stimulate the shedding of dead skin cells and encourage new cell growth.',
    img: oliveImg,
  },
  {
    id: 4,
    name: 'Aloe Vera',
    description:
      "The soothing properties of aloe vera help to protect the skin and reinforce its natural barrier function. It's a powerful hydrator, packed with antioxidants and minerals that boost the healing process.",
    img: aloeveraImg,
  },
]

const IngredientsSection = () => {
  return (
    <section className="relative">
      <img
        alt="bg"
        src={bgImg}
        className="object-contain relative -ml-1 w-full"
      />
      {/* absolute left-1/2 -translate-x-1/2 top-40 */}
      <div className="bg-[#ECA1B5] w-[99.2%] sm:w-[99.4%] md:w-[99.5%] lg:w-[99.7%] xl:w-[99.78%]">
        <h6 className="text-white font-bold text-2xl md:text-3xl absolute left-1/2 -translate-x-1/2 lg:top-48 xl:top-56 top-16 sm:top-24 w-full text-center lg:w-auto lg:text-left">
          Main Ingredients & Benefits
        </h6>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-screen-xl mx-auto pb-8 ">
          {ingredientsData.map(data => (
            <div
              key={data.id}
              className="flex flex-col px-10 2xl:-translate-y-1/4"
            >
              <img
                src={data.img}
                alt="ingredients"
                className="h-24 mx-auto object-contain mb-10"
              />
              <h6 className="text-white font-semibold text-center text-xl mb-2">
                {data.name}
              </h6>
              <p className="text-white text-sm w-[80%] mx-auto hyphens">
                {data.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default IngredientsSection
