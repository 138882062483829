/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  return (
    <header className="bg-[#eca1b5] py-3 px-6 border-b border-white">
      <ul className="flex justify-center sm:justify-end items-center max-w-screen-2xl mx-auto">
        <li className="navItems">
          <a href="#" className="navLinks">
            Home
          </a>
        </li>
        <li className="navItems">
          <a href="https://cradlemebaby.shop" className="navLinks">
            Shop
          </a>
        </li>
        <li className="navItems">
          <a href="#" className="navLinks">
            About
          </a>
        </li>
      </ul>
    </header>
  )
}
export default Header
