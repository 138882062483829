import { useState } from 'react';
import creamImg from '../assets/product.png';
import starImg from '../assets/p 3.png';
import brandImg from '../assets/p 4.png';

const HeroSection = () => {
  const [ratings] = useState(5);
  return (
    <section className="heroSection__img relative">
      <div className="w-full h-full relative max-w-screen-xl mx-auto">
        <img
          alt="Chloe Joie"
          src={brandImg}
          className="p-6  w-96 mx-auto sm:w-auto h-40 lg:h-48"
        />

        <img
          src={creamImg}
          className="sm:absolute md:-translate-x-[50%] md:-translate-y-[22%] sm:-translate-x-[50%] mt-14 sm:mt-8 md:mt-14 left-1/2 tall:-mt-6 maxTallest:mt-6"
          alt="cream"
        />

        {/*  sm:mt-6 md:mt-0 */}
        <div className="flex flex-col hyphens justify-center items-center h-auto relative z-50 sm:h-full sm:items-end sm:justify-end w-fit xl:w-64 mx-auto xl:mx-0 xl:absolute right-2 sm:-bottom-28 md:-bottom-24 sm:-mt-0 px-4 pb-2 phone:mt-0">
          <h3 className="font-medium text-xl text-center xl:text-end">
            CHLOE JOIE ANTI - STRETCH MARK CREAM
          </h3>
          <div className="flex gap-x-1 mx-auto xl:mx-0">
            {Array(ratings)
              .fill()
              .map((_, i) => (
                <img key={i} src={starImg} alt="star" className="h-5" />
              ))}
          </div>
          <a
            href="https://checkout.cradlemebaby.com/dl/8tf-faf-ng7"
            className="mx-auto xl:mx-0"
          >
            <button className="uppercase bg-[#eca1b5] rounded-full text-white py-2 px-6 w-full text-xl font-bold my-3">
              Add To Cart
            </button>
          </a>
          <p className="font-medium text-lg mx-auto xl:mx-0">$39.95</p>
        </div>
      </div>
    </section>
  );
};
export default HeroSection;
