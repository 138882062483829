import beforeImg from '../assets/p 7.png'
import afterImg from '../assets/p 8.png'
import starImg from '../assets/p 9.png'
import applyImg from '../assets/p 10.png'
const ResultsSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-8">
      <div className="flex gap-x-20 justify-center">
        <div className="flex flex-col items-center">
          <img alt="before" src={beforeImg} className="md:h-96" />
          <p className="text-[#eca1b5] text-2xl font-bold  mt-6">BEFORE</p>
        </div>

        <div className="flex flex-col items-center">
          <img alt="after" src={afterImg} className="md:h-96" />
          <p className="text-[#eca1b5] text-2xl font-bold  mt-6">AFTER</p>
        </div>
      </div>

      <div className="my-10 py-8">
        <div className="flex justify-center items-center mb-12">
          <img alt="star" src={starImg} className="h-20" />
          <h6 className="font-bold text-2xl md:text-3xl px-4  md:px-10 text-center uppercase">
            How long does it <br /> take to see results?
          </h6>
          <img alt="star" src={starImg} className="h-20" />
        </div>
        <div className="space-y-6">
          <p className="resultsPara">
            While stretch marks may be seen by some women as a reminder of the
            beautiful experience of pregnancy, other women may see these marks
            as an unsightly scar.
          </p>
          <p className="resultsPara">
            Pregnancy stretch marks may occur when your skin stretches and
            shrinks quickly, accommodating the weight gained and lost during the
            pregnancy process.
          </p>
          <p className="resultsPara">
            For the best results, we recommend using our Stretch Marks Cream
            consistently for at least 4 to 6 weeks before you see the results.
            Remember - consistency is key!
          </p>
        </div>
      </div>

      <div className="px-10">
        <h6 className="font-bold text-2xl md:text-3xl text-center mb-4">
          How to apply the cream
        </h6>
        <p className="font-medium text-sm text-center md:w-1/2 mx-auto">
          You should apply the cream 2x per day on clean skin, making sure to
          lightly massage the affected areas to improve blood flow and
          circulation
        </p>
        <img
          alt="apply_cream"
          src={applyImg}
          className="h-96 mx-auto mt-10 mb-2"
        />
      </div>
    </section>
  )
}
export default ResultsSection
