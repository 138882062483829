import benefitImg from '../assets/p 5.png';
const benefitsData = [
  {
    id: 0,
    name: 'Intensive Cream Formula',
    description:
      'This is a the perfect blend of essential oils and ingredients that will visibly reduce stretch marks and hydrate your gentle skin in an all-natural and organic way.',
  },
  {
    id: 1,
    name: 'Unique Triple-Action Formula',
    description:
      'It penetrates beneath the surface of the skin to lock in moisture to help cell renewal and aid collagen formation.',
  },
  {
    id: 2,
    name: 'CHEMICAL FREE',
    description:
      'This skin care solution is perfect for expecting Moms with sensitive skin. It’s free from parabens, synthetics and dyes, phthalates and sulfates making it safe and easy to use.',
  },
];
const BenefitsSection = () => {
  return (
    <section className="relative mt-64 py-4  sm:py-12 max-w-screen-xl mx-auto px-6">
      <h5 className="uppercase font-bold text-3xl md:text-4xl text-center">
        Essential Benefits
      </h5>
      <div className="flex flex-col md:flex-row gap-10 mt-8 justify-center sm:w-2/3 sm:mx-auto md:w-fit">
        {benefitsData.map(data => (
          <div key={data.id} className="flex flex-col basis-full">
            {/* flex-1  w-[33%] does same thing above */}
            <img
              src={benefitImg}
              alt="benefit__icon"
              className="h-14 mb-8 object-contain"
            />
            <h6 className="uppercase font-semibold mb-2 text-center">
              {data.name}
            </h6>
            <p className="hyphens text-sm text-medium w-min min-w-full text-center">
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default BenefitsSection;
